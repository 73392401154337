@font-face {
    font-family: MontserratRegular;
    src: url(../../assets/fonts/Montserrat-Regular.ttf)
  }

@font-face {
  font-family: MontserratBold;
  src: url(../../assets/fonts/Montserrat-Bold.ttf)
}





.font-default {
    font-family: MontserratRegular;
}